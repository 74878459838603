<template>
  <div class="baseDetail_bg">
    <div class="baseDetail">
      <div class="baseDetail_head">
        <span class="baseDetail_head_0"><i class="el-icon-s-home"></i> > </span>
        <span class="baseDetail_head_1">中峪药材基地 > </span>
        <span class="baseDetail_head_2">{{ nameList[0] }}</span>
        <span class="baseDetail_head_3"> > {{ nameList[1] }}</span>
        <span class="baseDetail_head_3"> > {{ nameList[2] }}</span>
      </div>
      <el-carousel indicator-position="outside" arrow="never" height="550px">
        <el-carousel-item v-for="item in detailInfo.productDTOList" :key="item.projectId">
          <div class="baseDetail_head1">
            <div><img :src="item.projectProductImage + imgSuffix" width="640px" /></div>
            <!-- <div><img src="../../static/image/巴戟天.jpg" width="640px" /></div> -->
            <div class="baseDetail_head1_right">
              <div class="baseDetail_head1_right_name">{{ item.projectProductName }}</div>
              <div class="baseDetail_head1_right_num">编号：{{ item.projectProductCode }}</div>
              <div class="baseDetail_head1_right_detail">
                {{ item.projectProductDesc }}
              </div>
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="baseDetail_body">
      <div class="baseDetail_body_title">
        <div class="baseDetail_body_title_left">▶▶</div>
        土地介绍
        <div class="baseDetail_body_title_right">◀◀</div>
      </div>
      <div class="baseDetail_body_list">
        <div class="baseDetail_body_list_item" v-for="item in detailInfo.landDTOList" :key="item.projectId">
          <img :src="item.landImage + imgSuffix" width="620px" />
          <div class="baseDetail_body_list_item_content">
            <div class="baseDetail_body_list_item_content_name">
              土地编号：<span class="baseDetail_body_list_item_content_value">{{ item.projectLandCode }}</span>
            </div>
            <div class="baseDetail_body_list_item_content_name">
              种植日期：<span class="baseDetail_body_list_item_content_value">{{ item.plantDate }}</span>
            </div>
            <div class="baseDetail_body_list_item_content_name">
              有 效 期 ：<span class="baseDetail_body_list_item_content_value">{{ item.landExpireDate }}</span>
            </div>
            <div class="baseDetail_body_list_item_content_name">
              成熟日期：<span class="baseDetail_body_list_item_content_value">{{ item.matureDate }}</span>
            </div>
            <div class="baseDetail_body_list_item_content_name">
              交 割 期 ：<span class="baseDetail_body_list_item_content_value">{{ item.deliveryDate }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="baseDetail_body_title" style="margin-top: 120px;">
        <div class="baseDetail_body_title_left">▶▶</div>
        基地介绍
        <div class="baseDetail_body_title_right">◀◀</div>
      </div>
    </div>
    <div class="baseDetail_body_intro">
      <div class="baseDetail_body_intro_head">
        基地承包：{{ detailInfo.base.contractor }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;工作人员：{{ detailInfo.base.worker }}
      </div>
      <div class="baseDetail_body_intro_line"></div>
      <div class="baseDetail_body_intro_foot">
        <div>
          {{ detailInfo.base.temperature }}℃<br /><span class="baseDetail_body_intro_foot_tips">温度</span>
        </div>
        <div>{{ detailInfo.base.humidity }}%<br /><span class="baseDetail_body_intro_foot_tips">湿度</span></div>
        <div>{{ detailInfo.base.rainfall }}%<br /><span class="baseDetail_body_intro_foot_tips">降雨量</span></div>
        <div>{{ detailInfo.base.diseaseAndPest }}%<br /><span class="baseDetail_body_intro_foot_tips">病害虫</span></div>
      </div>
    </div>
    <div class="baseDetail_body">
      <div class="baseDetail_body_img_list">
        <img :src="item + imgSuffix" v-for="item in baseImageList" :key="item" v-if="item" style="max-width: 620px;" />
      </div>
    </div>
  </div>
</template>
<script>
import { getPojectDetail } from '@/api/index.js'
export default {
  name: "baseDetail",
  data() {
    return {
      imgSuffixTime: null,
      imgSuffix: '?x-oss-process=image/resize,m_lfit,w_300',
      activeTree: 0,
      detail: false,
      detailInfo: {
        base: {}
      },
      baseImageList: [],
      nameList: []
    };
  },
  computed: {},
  mounted() {
    this.imgSuffixTime = setTimeout(() => {
      this.imgSuffix = ''
    }, 4000)
    window.scroll(0, 0);
    this.getPojectDetail()
    this.nameList = this.$route.query.name.split(',')
  },
  methods: {
    getPojectDetail() {
      getPojectDetail(this.$route.query.id).then((res) => {
        this.detailInfo = res.data
        if (!this.detailInfo.base) {
          this.detailInfo.base = {}
        }
        if (this.detailInfo.base.baseImages) {
          this.baseImageList = this.detailInfo.base.baseImages.split(',')
        }
      })
    }
  },
  beforeDestroy() {
    clearTimeout(this.imgSuffixTime)
  },
};
</script>
<style scoped lang="scss">
.baseDetail_bg {
  .baseDetail {
    width: 100%;
    background: url("../../static/image/introduction_bg2.png") no-repeat;
    background-size: cover;
    height: 800px;
  }

  .baseDetail_head {
    width: 1280px;
    margin: 0 auto;
    padding: 50px 0;

    .baseDetail_head_0 {
      color: #858585;
    }

    .baseDetail_head_1 {
      color: #858585;
    }

    .baseDetail_head_2 {
      color: #354a32;
    }

    .baseDetail_head_3 {
      color: #354a32;
    }
  }

  .baseDetail_head1 {
    width: 1280px;
    margin: 0 auto;
    display: flex;
    margin-top: 50px;

    .baseDetail_head1_right {
      margin-left: 40px;

      .baseDetail_head1_right_name {
        font-size: 40px;
        font-weight: bold;
        color: #354a32;
        line-height: 21px;
        letter-spacing: 2px;
        margin-top: 60px;
      }

      .baseDetail_head1_right_num {
        font-size: 28px;
        font-weight: 500;
        color: #354a32;
        line-height: 21px;
        letter-spacing: 1px;
        margin-top: 60px;
      }

      .baseDetail_head1_right_detail {
        font-size: 18px;
        font-weight: 400;
        color: #000000;
        line-height: 34px;
        letter-spacing: 1px;
        margin-top: 60px;
      }
    }
  }

  .baseDetail_body {
    width: 1280px;
    margin: 0 auto;
    margin-top: 60px;

    .baseDetail_body_title {
      font-size: 36px;
      font-weight: bold;
      color: #354a32;
      line-height: 54px;
      letter-spacing: 2px;
      display: flex;
      justify-content: center;

      .baseDetail_body_title_left {
        font-size: 12px;
        margin-right: 30px;
      }

      .baseDetail_body_title_right {
        font-size: 12px;
        margin-left: 30px;
      }
    }

    .baseDetail_body_list {
      display: flex;
      justify-content: center;
      margin-top: 80px;

      .baseDetail_body_list_item:nth-child(2) {
        margin-left: 40px;
      }

      .baseDetail_body_list_item_content {
        background-color: #fff;
        box-shadow: 0px 2px 11px 0px rgba(53, 74, 50, 0.07);
        padding: 0 30px 30px 30px;

        .baseDetail_body_list_item_content_name {
          font-size: 18px;
          font-weight: 500;
          color: #354a32;
          line-height: 20px;
          text-shadow: 0px 2px 11px rgba(53, 74, 50, 0.07);
          margin-top: 26px;
        }

        .baseDetail_body_list_item_content_value {
          font-size: 18px;
          font-weight: 400;
          color: #6b766a;
          line-height: 20px;
          text-shadow: 0px 2px 11px rgba(53, 74, 50, 0.07);
        }
      }
    }

    .baseDetail_body_img_list {
      margin-top: 30px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      padding-bottom: 120px;

      img {
        margin-top: 50px;
        width: 620px;
      }
    }
  }

  .baseDetail_body_intro {
    height: 370px;
    width: 100%;
    text-align: center;
    background: url("../../static/newImg/位图14.jpg") no-repeat;
    background-size: cover;
    margin-top: 100px;

    .baseDetail_body_intro_head {
      font-size: 20px;
      font-weight: 500;
      color: #ffffff;
      line-height: 20px;
      letter-spacing: 1px;
      padding-top: 80px;
    }

    .baseDetail_body_intro_line {
      width: 1280px;
      height: 1px;
      background: #ffffff;
      opacity: 0.22;
      margin: 70px auto;
    }

    .baseDetail_body_intro_foot {
      width: 880px;
      display: flex;
      margin: 0 auto;
      padding-bottom: 50px;
      font-size: 49px;
      font-weight: 400;
      color: #ffffff;
      line-height: 36px;
      justify-content: space-between;

      .baseDetail_body_intro_foot_tips {
        font-size: 16px;
        font-weight: 400;
        color: #ffffff;
        line-height: 22px;
      }
    }
  }
}
</style>
